export const routes = {
  auth: {
    login: 'auth/login',
    forgotPassword: '/auth/forgot-password',
  },
  pages: {
    dashboard: '/pages/dashboard',
    eventList: '/pages/events',
    registrations: '/pages/registrations',
    reports: '/pages/reports',
    promocode: '/pages/promo-codes',
    userList: '/pages/users',
    auditLogs: '/pages/audit-log',
    runner: '/pages/runners',
  },
};
