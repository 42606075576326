import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @Input() page!: number;
  @Input() isMore!: boolean;
  @Input() isLoading: boolean = false;
  @Output() pageChange: EventEmitter<any> = new EventEmitter();
  isBtn: string = '';

  constructor() {}
  onPageChangeIncrese() {
    this.page = this.page + 1;
    this.pageChange.next(this.page);
    this.isBtn = 'next';
  }
  onPageChangeDecrease() {
    this.page = this.page - 1;
    this.pageChange.next(this.page);
    this.isBtn = 'prev';
  }
}
