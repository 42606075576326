import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any, objectKey: string, searchText: string = ''): any {
    if (items && objectKey) {
      return items.filter((item:any) => {
          return (item[objectKey].toLowerCase().indexOf(searchText.toLowerCase()) > -1);
      });
  }
  }
}
