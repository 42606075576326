<div class="header-main">
  <div class="custom-header">
    <div class="header-container-left">
      <div class="logo-container">
        <a class="logo"><img src="assets/images/ragnar-logo.png" /></a>
      </div>
      <div class="edge"></div>
    </div>
    <div class="header-container-center">
      <mat-tab-group #selectedIndex mat-align-tabs="center" *ngIf="user" [(selectedIndex)]="activeTab" (selectedTabChange)="onTabChanged($event)">
        <mat-tab [label]="routes.pages.dashboard">
          <ng-template mat-tab-label>{{ 'header.dashboard' | translate }}</ng-template>
        </mat-tab>
    
        <mat-tab  [label]="routes.pages.eventList" *ngIf="'header.events' | translate | includedByUserRoles : user.role">
          <ng-template mat-tab-label>{{ 'header.events' | translate }}</ng-template>
        </mat-tab>
    
        <mat-tab [label]="routes.pages.registrations" *ngIf="'header.registrations' | translate | includedByUserRoles : user.role">
          <ng-template mat-tab-label>{{ 'header.registrations' | translate }}</ng-template>
        </mat-tab>
    
        <mat-tab [label]="routes.pages.reports" *ngIf="'header.reports' | translate | includedByUserRoles : user.role">
          <ng-template mat-tab-label>{{ 'header.reports' | translate }}</ng-template>
        </mat-tab>
    
        <mat-tab [label]="routes.pages.promocode" *ngIf="'header.promocode' | translate | includedByUserRoles : user.role">
          <ng-template mat-tab-label>{{ 'header.promocode' | translate }}</ng-template>
        </mat-tab>
    
        <mat-tab [label]="routes.pages.userList" *ngIf="'header.users' | translate | includedByUserRoles : user.role">
          <ng-template mat-tab-label>{{ 'header.users' | translate }}</ng-template>
        </mat-tab>
    
        <mat-tab [label]="routes.pages.auditLogs" *ngIf="'header.audit-log' | translate | includedByUserRoles : user.role">
          <ng-template mat-tab-label>{{ 'header.audit-log' | translate }}</ng-template>
        </mat-tab>
    
        <mat-tab [label]="routes.pages.runner" *ngIf="'header.runner' | translate | includedByUserRoles : user.role">
          <ng-template mat-tab-label>{{ 'header.runner' | translate }}</ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
    
    <div class="header-container-right" *ngIf="user" (click)="logout()">
      <span class="user-name">{{ user.username }}</span>
      <mat-icon
        class="custom-logout"
        aria-hidden="false"
        aria-label="Logout"
        fontIcon="input"
      ></mat-icon>
    </div>
  </div>
</div>
