import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../@core/core.module';
import { HttpLoaderFactory } from '../app.module';
import { DeletePopupComponent } from './delete-popup/delete-popup.component';
import { HeaderComponent } from './header/header.component';
import { PaginatorComponent } from './paginator/paginator.component';

@NgModule({
  imports: [
    MatIconModule,
    MatTabsModule,
    RouterModule,
    CoreModule,
    MatFormFieldModule,
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [PaginatorComponent, HeaderComponent, DeletePopupComponent],
  declarations: [PaginatorComponent, HeaderComponent, DeletePopupComponent],
  providers: [],
})
export class CommonComponentModule {}
