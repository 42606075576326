import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  transform(input: any, format?: string | undefined) {
    if (input instanceof Date) input = input.valueOf() / 1000;
    if (typeof input === 'number') input = input.toString();

    let seconds:any = parseInt(input, 10);
    if (!isNaN(seconds)) {
        return moment("1970-01-01").startOf('day').seconds(seconds).format(format || 'hh:mm A');
    } else {
        return input;
    }
  }
}
