import { rolesConstant } from './../@core/services/data/var.constant.service';
import { PagesModule } from './pages.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../@core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    data: {
      roles: [
        rolesConstant.admin,
        rolesConstant.customerService,
        rolesConstant.finance,
        rolesConstant.marketing,
        rolesConstant.mdm,
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
        rolesConstant.fieldOps,
      ],
      headerAccess: [
        rolesConstant.admin,
        rolesConstant.fieldOps,
        rolesConstant.finance,
        rolesConstant.raceDirector,
        rolesConstant.marketing,
        rolesConstant.mdm,
        rolesConstant.fieldOps,

      ]
    },
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    data: {
      roles: [rolesConstant.admin, rolesConstant.marketing, rolesConstant.mdm],
      headerAccess: [
        rolesConstant.admin,
      ]
    },
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'events',
    canActivate: [AuthGuard],
    data: {
      roles: [
        rolesConstant.admin,
        rolesConstant.customerService,
        rolesConstant.marketing,
        rolesConstant.mdm,
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
        rolesConstant.fieldOps,
      ],
      headerAccess: [
        rolesConstant.admin,
        rolesConstant.fieldOps,
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
        rolesConstant.marketing,
        rolesConstant.customerService,
        rolesConstant.mdm,
        rolesConstant.fieldOps,

      ],
      create: [
        rolesConstant.customerService,
        rolesConstant.mdm,
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
        rolesConstant.fieldOps
      ],
      delete: [
        rolesConstant.customerService,
        rolesConstant.finance,
        rolesConstant.marketing,
        rolesConstant.mdm,
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
        rolesConstant.fieldOps
      ],
      detailsReadOnlyInfo: [
        rolesConstant.customerService, ,
        rolesConstant.mdm,
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
      ],
      detailsReadOnlyDates: [
        rolesConstant.customerService, ,
        rolesConstant.mdm,
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
        rolesConstant.fieldOps
      ],
      readOnlyRegFunnel: [
        rolesConstant.customerService, ,
        rolesConstant.mdm,
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
        rolesConstant.fieldOps
      ],
      readOnlyPrices: [
        rolesConstant.customerService, ,
        rolesConstant.mdm,
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
        rolesConstant.fieldOps
      ],
      readOnlyPrmoCode: [
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
        rolesConstant.fieldOps
      ],
      readOnlyTaxes: [
        rolesConstant.customerService, ,
        rolesConstant.mdm,
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
        rolesConstant.fieldOps
      ],

      
      readOnlyConf: [
        rolesConstant.customerService,
        rolesConstant.mdm,
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
        rolesConstant.fieldOps
      ],
      readOnlyTeamData:[
        rolesConstant.customerService,
        rolesConstant.fieldOps
      ],
      readOnlyTeamManage:[
        rolesConstant.mdm,
        rolesConstant.marketing,
        rolesConstant.volunteerManager
      ],
      readOnlyTeamManageRunner:[
        rolesConstant.mdm,
        rolesConstant.marketing,
      ],
      readOnlyTeamManageVolunteer:[
        rolesConstant.mdm,
        rolesConstant.marketing
      ],
      readOnlyRunners:[
        rolesConstant.mdm,
        rolesConstant.marketing
      ],
      readOnlyAddRunners:[
        rolesConstant.marketing,
        rolesConstant.mdm,
        rolesConstant.volunteerManager
      ],
      readOnlyStartTime:[
        rolesConstant.marketing,
        rolesConstant.mdm,
        rolesConstant.volunteerManager
      ],
      readOnlyMarketing:[
        rolesConstant.marketing
      ],
      readOnlyRaceDirector:[
        rolesConstant.raceDirector
      ],
      readOnlyVolunteerManger:[
        rolesConstant.volunteerManager
      ],
      readOnlyVolRegFunnel:[
        rolesConstant.customerService,
        rolesConstant.mdm
      ],
      readOnlyVolRequirement:[
        rolesConstant.customerService,
        rolesConstant.mdm
      ],
      readOnlyVolJob:[
        rolesConstant.customerService,
        rolesConstant.marketing,
        rolesConstant.mdm
      ],
      readOnlyGroup:[
        rolesConstant.customerService,
        rolesConstant.marketing,
        rolesConstant.mdm
      ]

      // delete: ['CUSTOMER_SERVICE', 'FINANCE', 'MARKETING', 'MDM', 'RACE_DIRECTOR', 'VOLUNTEER_MANAGER', 'FIELD_OPS'],
      // details: {
      //     readOnlyInfo: ['CUSTOMER_SERVICE', 'MDM', 'RACE_DIRECTOR', 'VOLUNTEER_MANAGER'],
      //     readOnlyDates: ['CUSTOMER_SERVICE', 'MDM', 'RACE_DIRECTOR', 'VOLUNTEER_MANAGER', 'FIELD_OPS'],
      // },
    },
    loadChildren: () =>
      import('./events/events.module').then((m) => m.EventsModule),
  },
  {
    path: 'registrations',
    canActivate: [AuthGuard],
    data: {
      roles: [rolesConstant.admin, rolesConstant.marketing, rolesConstant.mdm],
      headerAccess: [
        rolesConstant.admin,
        rolesConstant.marketing,
        rolesConstant.mdm,

      ]
    },
    loadChildren: () =>
      import('./registrations/registrations.module').then(
        (m) => m.RegistrationsModule
      ),
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    data: {
      roles: [
        rolesConstant.admin,
        rolesConstant.customerService,
        rolesConstant.finance,
        rolesConstant.marketing,
        rolesConstant.mdm,
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
        rolesConstant.fieldOps,
      ],
      headerAccess: [
        rolesConstant.admin,
        rolesConstant.fieldOps,
        rolesConstant.finance,
        rolesConstant.raceDirector,
        rolesConstant.volunteerManager,
        rolesConstant.marketing,
        rolesConstant.customerService,
        rolesConstant.mdm,
        rolesConstant.fieldOps,


      ]
    },
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'promo-codes',
    canActivate: [AuthGuard],
    data: {
      roles: [
        rolesConstant.admin,
        rolesConstant.customerService,
        rolesConstant.marketing,
        rolesConstant.mdm,
        rolesConstant.fieldOps,
      ],
      headerAccess: [
        rolesConstant.admin,
        rolesConstant.fieldOps,
        rolesConstant.marketing,
        rolesConstant.customerService,
        rolesConstant.mdm,
        rolesConstant.fieldOps,

      ]
    },
    loadChildren: () =>
      import('./promocodes/promocodes.module').then((m) => m.PromocodesModule),
  },
  {
    path: 'audit-log',
    canActivate: [AuthGuard],
    data: {
      roles: [rolesConstant.admin],
      headerAccess: [
        rolesConstant.admin,
      ]
    },
    loadChildren: () =>
      import('./audit-logs/audit-logs.module').then((m) => m.AuditLogsModule),
  },
  {
    path: 'runners',
    canActivate: [AuthGuard],
    data: {
      roles: [rolesConstant.admin, rolesConstant.customerService],
      headerAccess: [
        rolesConstant.admin,
        rolesConstant.customerService,
      ]
    },
    loadChildren: () =>
      import('./runners/runners.module').then((m) => m.RunnersModule),
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
export let routesObj = routes;
