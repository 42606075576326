import {
    Directive,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';

@Directive({
    selector: '[ngInit]',
})
export class NgInitDirective implements OnInit {

    @Output() ngInit: EventEmitter<any> = new EventEmitter();
    constructor(private directiveElement: ElementRef) { }
    ngOnInit() {
        this.ngInit.emit();
    }
}
