import { Pipe, PipeTransform } from '@angular/core';
import { routesObj } from 'src/app/pages/pages-routing.module';

@Pipe({ name: 'includedByUserRoles' })
export class IncludedByUserRoles implements PipeTransform {
  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['headerAccess'].includes(role);
  }
}


@Pipe({ name: 'createEventRole' })
export class CreateEventRole implements PipeTransform {
  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['create'].includes(role);
  }
}

@Pipe({ name: 'deleteEventRole' })
export class DeleteEventRole implements PipeTransform {
  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['delete'].includes(role);
  }
}


@Pipe({ name: 'detailsReadOnlyInfo' })
export class DetailsReadOnlyInfo implements PipeTransform {
  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['detailsReadOnlyInfo'].includes(role);
  }
}


@Pipe({ name: 'detailsReadOnlyDates' })
export class DetailsReadOnlyDates implements PipeTransform {
  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['detailsReadOnlyDates'].includes(role);
  }
}

@Pipe({ name: 'readOnlyRegFunnel' })
export class ReadOnlyRegFunnel implements PipeTransform {
  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyRegFunnel'].includes(role);
  }
}

@Pipe({ name: 'readOnlyPrices' })
export class ReadOnlyPrices implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {

    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyPrices'].includes(role);
  }
}

@Pipe({ name: 'readOnlyPrmoCode' })
export class ReadOnlyPrmoCode implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {

    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyPrmoCode'].includes(role);
  }
}

@Pipe({ name: 'readOnlyTaxes' })
export class ReadOnlyTaxes implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {

    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyTaxes'].includes(role);
  }
}

@Pipe({ name: 'readOnlyConf' })
export class readOnlyConf implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyConf'].includes(role);
  }
}

@Pipe({ name: 'readOnlyTeamData' })
export class readOnlyTeamData implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyTeamData'].includes(role);
  }
}
@Pipe({ name: 'readOnlyTeamManage' })
export class ReadOnlyTeamManage implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {

    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyTeamManage'].includes(role);
  }
}

@Pipe({ name: 'readOnlyTeamManageRunner' })
export class readOnlyTeamManageRunner implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyTeamManageRunner'].includes(role);
  }
}

@Pipe({ name: 'readOnlyTeamManageVolunteer' })
export class readOnlyTeamManageVolunteer implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyTeamManageVolunteer'].includes(role);
  }
}

@Pipe({ name: 'readOnlyRunners' })
export class readOnlyRunners implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyRunners'].includes(role);
  }
}

@Pipe({ name: 'readOnlyAddRunners' })
export class readOnlyAddRunners implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyAddRunners'].includes(role);
  }
}

@Pipe({ name: 'readOnlyStartTime' })
export class readOnlyStartTime implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyStartTime'].includes(role);
  }
}

@Pipe({ name: 'readOnlyMarketing' })
export class readOnlyMarketing implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyMarketing'].includes(role);
  }
}

@Pipe({ name: 'readOnlyRaceDirector' })
export class readOnlyRaceDirector implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyRaceDirector'].includes(role);
  }
}

@Pipe({ name: 'readOnlyVolunteerManger' })
export class readOnlyVolunteerManger implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyVolunteerManger'].includes(role);
  }
}

@Pipe({ name: 'readOnlyVolRegFunnel' })
export class readOnlyVolRegFunnel implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyVolRegFunnel'].includes(role);
  }
}

@Pipe({ name: 'readOnlyVolRequirement' })
export class readOnlyVolRequirement implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyVolRequirement'].includes(role);
  }
}

@Pipe({ name: 'readOnlyVolJob' })
export class readOnlyVolJob implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyVolJob'].includes(role);
  }
}

@Pipe({ name: 'readOnlyGroup' })
export class readOnlyGroup implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {
    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyGroup'].includes(role);
  }
}

@Pipe({ name: 'readOnlyRunners' })
export class ReadOnlyRunners implements PipeTransform {

  constructor() { }
  transform(name: string, role: string) {

    let routePath = routesObj.find(
      (route) => route.path === name.replace(' ', '-').toLowerCase()
    );
    return routePath?.data?.['readOnlyRunners'].includes(role);
  }

}

