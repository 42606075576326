export class User {
  username: string = '';
  given_name: string = '';
  family_name: string = '';
  email: string = '';
  role: UserRole = 'ADMIN';
  password: string = '';
  confirmationCode: string = '';
  full_name: string = '';
  panelState: Boolean = false;

  init(data?: any): void {
    if (data) {
      this.username =
        data['username'] || data['Username'] || data['cognito:username'];
      this.given_name = data['given_name'] || data['name'];
      this.family_name = data['family_name'] || data['custom:lastName'];
      this.password = data['password'];
      this.email = data['email'];
      this.role = data['role'] || data['custom:role'];
    }
  }

  public fromJS(data: any): User {
    let result = new User();
    result.init(data);
    return result;
  }

  static fromJS(data: any): User {
    let result = new User();
    result.init(data);
    return result;
  }

  toJSON(data?: any): Object {
    data = typeof data === 'object' ? data : {};

    data['username'] = this.username;
    data['given_name'] = this.given_name;
    data['family_name'] = this.family_name;
    data['password'] = this.password;
    data['email'] = this.email;
    data['role'] = this.role;

    return data;
  }
}
export type UserRole =
  | 'FINANCE'
  | 'ADMIN'
  | 'RACE_DIRECTOR'
  | 'VOLUNTEER_MANAGER'
  | 'MARKETING'
  | 'CUSTOMER_SERVICE'
  | 'MDM'
  | 'FIELD_OPS';
