import { AddHeaderInterceptor } from './interceptors/add-header.interceptor';
import { NgModule } from '@angular/core';
import { LoaderDirective } from './directive/loader.directive';
import { CustomDateRangePipe } from './pipes/custom-date-range.pipe';
import { CreateEventRole, DeleteEventRole, DetailsReadOnlyDates, DetailsReadOnlyInfo, 
  IncludedByUserRoles, readOnlyAddRunners, readOnlyConf,
 readOnlyGroup, readOnlyMarketing, ReadOnlyPrices, ReadOnlyPrmoCode, 
 readOnlyRaceDirector, ReadOnlyRegFunnel, readOnlyRunners, readOnlyStartTime, 
 ReadOnlyTaxes, readOnlyTeamData, ReadOnlyTeamManage, readOnlyTeamManageRunner, 
 readOnlyTeamManageVolunteer, readOnlyVolJob, readOnlyVolRegFunnel, readOnlyVolRequirement, 
 readOnlyVolunteerManger } from './pipes/included-by-user-roles.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NgInitDirective } from './directive/ng-init.directive';
import { PacePipe } from './pipes/pace.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { FullnamePipe } from './pipes/fullName.pipe';
import { DateRangePipe } from './pipes/date-range.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { TimeOfsetPipe } from './pipes/time-ofset.pipe';
// const UTIL_SERVICES = [
// ];
const PIPES = [
  IncludedByUserRoles,
  CustomDateRangePipe,
  OrderByPipe,
  TimeFormatPipe,
  CreateEventRole,
  DetailsReadOnlyInfo,
  DetailsReadOnlyDates,
  SafeHtmlPipe,
  ReadOnlyRegFunnel,
  PacePipe,
  DateFormatPipe,
  FullnamePipe,
  ReadOnlyPrices,
  ReadOnlyPrmoCode,
  ReadOnlyTaxes,
  DateRangePipe,
  readOnlyConf,
  readOnlyTeamData,
  ReadOnlyTeamManage,
  readOnlyTeamManageRunner,
  readOnlyTeamManageVolunteer,
  readOnlyRunners,
  readOnlyAddRunners,
  readOnlyStartTime,
  readOnlyMarketing,
  readOnlyRaceDirector,
  readOnlyVolunteerManger,
  readOnlyVolRegFunnel,
  readOnlyVolRequirement,
  readOnlyVolJob,
  readOnlyGroup,
  FilterPipe,
  TimeOfsetPipe,
  DeleteEventRole
];

const DIRECTIVES = [LoaderDirective, NgInitDirective];
const UTIL_SERVICES = [AddHeaderInterceptor];

@NgModule({
  imports: [],
  exports: [...PIPES, ...DIRECTIVES],
  declarations: [...PIPES, ...DIRECTIVES],
  providers: [...UTIL_SERVICES],
})
export class CoreModule {}
