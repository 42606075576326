import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeOfset'
})
export class TimeOfsetPipe implements PipeTransform {

  transform(value: any, offsetMinutes: number): any {
    if (value) {
      const adjustedDate = moment(value).utcOffset(offsetMinutes);
      return adjustedDate.format('MMM DD, YYYY HH:mm');
    }
    return null;
  }
}
