<div class="user-wait-popup">
  <div class="user-wait-popup-p1">
    <h2>Wait!</h2>
    <p>{{ message }}</p>
  </div>
  <div class="user-wait-popup-p2">
    <button *ngIf="isCancleBtnDisplay"[ngClass]="{'btnAlign':!isConfirmBtnDisplay}"  (click)="cancel()">No, I'm scared</button>
    <button *ngIf="isConfirmBtnDisplay" (click)="confirm()" [disabled]="isBtnLoading">
      <span *ngIf="!isBtnLoading">Let's do it!</span>
      <mat-icon *ngIf="isBtnLoading">
        <mat-spinner diameter="20"> </mat-spinner>
      </mat-icon>
    </button>
  </div>
</div>
