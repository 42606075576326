import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { ProfileService } from '../services/data/profile.service';
import { localStorageConstant } from '../services/data/var.constant.service';
import { routes } from '../utils/routes-constants';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  routes = routes;
  constructor(private router: Router, public profileService: ProfileService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const authToken = localStorage.getItem(localStorageConstant.jwtToken);
    if (authToken) {
      let routeData: any = route.data;
      let userData: User = this.profileService.getUserData.value;
      if (userData) {
        if (routeData['roles'].includes(userData.role)) {
          return true;
        } else {
          if (userData.role === 'FINANCE') {
            this.router.navigate([routes.pages.dashboard]);
          } else {
            this.router.navigate([routes.pages.eventList]);
          }

          return false;
        }
      } else {
        return false;
      }
    } else {
      this.router.navigate([routes.auth.login]);
      return false;
    }
  }
}
