import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'fullName',
})
export class FullnamePipe implements PipeTransform {
  transform(input: { firstName: string, lastName: string }): string {
    if (!input) {
      return '';
  }

  return [input.firstName, input.lastName].join(' ');
  }
}
