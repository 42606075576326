<div class="paginaton-block">
  <button
    [ngClass]="{ disable: page <= 0 }"
    (click)="onPageChangeDecrease()"
    class="custom-paginaton"
    [ngClass]="page <= 0 ? 'disable' : ''"
    [disabled]="page <= 0"
  >
    <mat-icon class="icon" matPrefix *ngIf="!isLoading"
      >keyboard_arrow_left</mat-icon
    >
    <span class="previous" *ngIf="!isLoading">Prev</span>
    <mat-icon *ngIf="isBtn === 'prev' && isLoading"
      ><mat-spinner diameter="20"> </mat-spinner
    ></mat-icon>
  </button>
  <span class="page-count">Page: {{ page + 1 }}</span>

  <button
    [ngClass]="{ disable: !isMore }"
    (click)="onPageChangeIncrese()"
    class="custom-paginaton"
    [disabled]="!isMore"
    [ngClass]="!isMore ? 'disable' : ''"
  >
    <span class="next" *ngIf="!isLoading">Next</span>
    <mat-icon class="icon" matSuffix *ngIf="!isLoading"
      >keyboard_arrow_right</mat-icon
    >
    <mat-icon *ngIf="isBtn === 'next' && isLoading">
      <mat-spinner diameter="20"> </mat-spinner
    ></mat-icon>
  </button>
</div>
