export class TokenData {
  email?: string | undefined;
  role?: string | undefined;
  initFromMemberObject(data?: any) {
    if (data) {
      this.email = data['email'];
      this.role = data['role'];
    }
  }
}
