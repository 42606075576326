import { ProfileService } from './../../@core/services/data/profile.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { routes } from '../../@core/utils/routes-constants';
import { User } from 'src/app/@core/models/user.model';
import {
  MatTabChangeEvent,
  MatTabGroup,
  _MatTabBodyBase,
} from '@angular/material/tabs';
type RouteKey = keyof typeof routes.pages;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnChanges {
  activeTab: number = -1;
  selectedIndex = -1;
  public routes = routes;
  @ViewChild('selectedIndex') tabGroup: MatTabGroup;
  user: any;
  initalCall: boolean;
  constructor(
    private router: Router,
    public profileService: ProfileService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }
  ngOnInit(): void {
    this.profileService.getUserData.subscribe((res: User) => {
      if(res){
      this.user = res;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          setTimeout(() => {
            this.tabGroup._allTabs.forEach((tab, index) => {
              if (event.url.includes(tab.textLabel)) {
                this.tabGroup.selectedIndex = index;
                this.activeTab = index;
              }
            });
          });
        }
      });
    }
    });
  }

  onTabChanged(event: MatTabChangeEvent): void {
    if (event.tab) {
      if (
        (event.tab.textLabel == routes.pages.eventList &&
          (this.router.url.includes('event-detail') ||
            this.router.url == '/')) ||
        (event.tab.textLabel === routes.pages.runner &&
          (this.router.url == '/' ||
            this.router.url.includes('runners/detail')))
      ) {
        return;
      } else {
        this.router.navigate([event.tab.textLabel]);
      }
    }
  }

  logout() {
    this.router.navigate([routes.auth.login]);
    localStorage.clear();
    this.profileService.getUserData.next(null);
    this.user = null;
  }
}
