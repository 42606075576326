import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenData } from '../../models/tokan-data.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  baseUrl = environment.profilesBaseUrl;
  getUserData = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) {}
  bindCognitoUserToLocalUser(cognitoUser: any, password: string) {
    return {
      username: cognitoUser.username,
      name: cognitoUser.attributes.given_name || cognitoUser.attributes.name,
      family_name: cognitoUser.attributes.family_name,
      password: password,
      email: cognitoUser.attributes.email,
      role:
        cognitoUser.attributes['custom:role'] || cognitoUser.attributes['role'],
    };
  }

  generateAdminToken(tokenData: any) {
    let url = `${this.baseUrl}/token`;
    let data = new TokenData();
    let header = {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json; charset=UTF-8',
    };
    data.initFromMemberObject(tokenData);
    return this.http.post(url, data, { headers: header }).toPromise();
  }
}
