import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'pace',
})
export class PacePipe implements PipeTransform {
  transform(input:any): string {
    var sec = parseInt((isNaN(input) ? 0 : input), 10);
    sec = (sec >= 0) ? sec : 0;
    var min = Math.floor(sec / 60);
    return (('0' + min).slice(-2)) + ":" + (('0' + (sec - (min * 60))).slice(-2));
  }
}
