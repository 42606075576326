import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { DATE_FORMAT, DATE_RANGE_DAY_FORMAT, DATE_RANGE_DAY_TIME_FORMAT, DATE_RANGE_MONTH_FORMAT, DATE_RANGE_MONTH_TIME_FORMAT, DATE_RANGE_TIME_FORMAT, DATE_RANGE_YEAR_FORMAT, DATE_RANGE_YEAR_TIME_FORMAT, DATE_TIME_FORMAT } from '../services/data/var.constant.service';
@Pipe({
  name: 'dateRange',
})
export class DateRangePipe implements PipeTransform {
  transform(dates: [moment.MomentInput, moment.MomentInput, number?, number?], customFormate?: string): string {
    let startDate = moment(dates[0]).utc(),
      endDate = moment(dates[1]).utc(),
      withTime = dates.length === 4,
      format = withTime ? DATE_RANGE_YEAR_TIME_FORMAT : DATE_RANGE_YEAR_FORMAT;
    if (!dates[0] || !dates[1] || !startDate.isValid() || !endDate.isValid()) {
      return '';
    }

    if (withTime) {
      startDate.add(dates[2], 'seconds');
      endDate.add(dates[3], 'seconds');
    }

    if (startDate.year() === endDate.year()) {
      format = withTime ? DATE_RANGE_MONTH_TIME_FORMAT : DATE_RANGE_MONTH_FORMAT;

      if (startDate.month() === endDate.month()) {
        format = withTime ? DATE_RANGE_DAY_TIME_FORMAT : DATE_RANGE_DAY_FORMAT;

        if (startDate.date() === endDate.date()) {
          format = DATE_RANGE_TIME_FORMAT;

          if (withTime) {
            if (startDate.valueOf() === endDate.valueOf()) {
              return startDate.format(DATE_TIME_FORMAT);
            }
          } else {
            return startDate.format(customFormate || DATE_FORMAT);
          }
        }
      }
    }

    return [startDate.format(customFormate || format[0]), endDate.format(customFormate || format[1])].join(' - ');
  }
}
