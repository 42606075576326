import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {
  DATE_RANGE_YEAR_FORMAT,
  DATE_RANGE_MONTH_FORMAT,
  DATE_RANGE_DAY_FORMAT,
  DATE_RANGE_TIME_FORMAT,
  DATE_FORMAT,
  DATE_RANGE_YEAR_TIME_FORMAT,
  DATE_RANGE_MONTH_TIME_FORMAT,
  DATE_RANGE_DAY_TIME_FORMAT,
  DATE_TIME_FORMAT,
} from '../services/data/var.constant.service';

@Pipe({
  name: 'customDateRangePipe',
})

/*
startDate, endDate and customFomate are optional parameters.
If you want to just add customFormate then set startDate and endDate to 0 i.e : 0 : 0 : 'dddd, MMM DD'
*/
export class CustomDateRangePipe implements PipeTransform {
  transform(
    dates: [string | undefined, string | undefined],
    startTime?: number | undefined,
    endTime?: number | undefined,
    customFormate?: string
  ): string {
    let startDate = moment(dates[0]).utc(),
      endDate = moment(dates[1]).utc(),
      format =
        startTime && endTime
          ? DATE_RANGE_YEAR_TIME_FORMAT
          : DATE_RANGE_YEAR_FORMAT;
    if (!dates[0] || !dates[1] || !startDate.isValid() || !endDate.isValid()) {
      return '';
    }

    if (startTime && endTime) {
      startDate.add(startTime, 'seconds');
      endDate.add(endTime, 'seconds');
    }

    if (startDate.year() === endDate.year()) {
      format =
        startTime && endTime
          ? DATE_RANGE_MONTH_TIME_FORMAT
          : DATE_RANGE_MONTH_FORMAT;

      if (startDate.month() === endDate.month()) {
        format =
          startTime && endTime
            ? DATE_RANGE_DAY_TIME_FORMAT
            : DATE_RANGE_DAY_FORMAT;

        if (startDate.date() === endDate.date()) {
          format = DATE_RANGE_TIME_FORMAT;

          if (startTime && endTime) {
            if (startDate.valueOf() === endDate.valueOf()) {
              return startDate.format(DATE_TIME_FORMAT);
            }
          } else {
            return startDate.format(customFormate || DATE_FORMAT);
          }
        }
      }
    }

    return [
      startDate.format(customFormate || format[0]),
      endDate.format(customFormate || format[1]),
    ].join(' - ');
  }
}
