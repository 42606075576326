import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss'],
})
export class DeletePopupComponent {
  message: String = '';
  isBtnLoading: Boolean = false;
  isCancleBtnDisplay: Boolean = true;
  isConfirmBtnDisplay: Boolean = true;
  @Output() submitClicked = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<DeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.data.type) {
      this.message = this.data.type
        // 'Are you sure you want to delete the ' + this.data.type + '?';
    } else {
      this.message = 'Are you sure you want to delete ?';
    }
    if (this.data.isConfirmBtnDisplay === false) { 
      this.isConfirmBtnDisplay = this.data.isConfirmBtnDisplay;
    }
  }

  confirm() {
    this.isBtnLoading = true;
    if (this.data.type) {
      this.submitClicked.emit(true);
      this.dialogRef.afterOpened();
    } else {
      this.isBtnLoading = false;
      this.submitClicked.emit(false);
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
