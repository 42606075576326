import { LocalStorageService } from 'src/app/@core/services/data/local-storage.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  constructor(
    public localStorage: LocalStorageService,
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.localStorage.getToken()) {
      if (request.headers.get("skip")){
        return next.handle(request);
      } else {
        request = request.clone({
          headers: request.headers
            .set('authorization', `${this.localStorage.getToken()}`)
            .set('x-api-key', environment.profilesApiKey),
        });
      }
    } else {
      request = request.clone({
        headers: request.headers.set('x-api-key', environment.profilesApiKey),
      });
    }
    // return next.handle(request);

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        if (error.status == 401) {
          localStorage.clear();
          window.location.href = '';
        }
        // if (error.status == 500) {
          // this.shared.presentToast(error.statusText + '. ' + 'please try again later');
          // this.router.navigate(['/']);
        // }
        return throwError(error);
      })
    );
  }
}
