import { LocalStorageService } from './@core/services/data/local-storage.service';
import { ProfileService } from './@core/services/data/profile.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from './@core/models/user.model';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ragnar-hub-v3';
  public user: User = new User();
  constructor(
    private translate: TranslateService,
    public profileService: ProfileService,
    public localStorageService: LocalStorageService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
  }
  ngOnInit(): void {
    let cognitoUser = this.localStorageService.getUser();
    if (cognitoUser) {
      this.user = this.user.fromJS(
        this.profileService.bindCognitoUserToLocalUser(cognitoUser, '')
      );
      this.profileService.getUserData.next(this.user);
    }
  }
}
